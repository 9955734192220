import jwtDecode from "jwt-decode";
import { db } from "../config/firebase";
import {
  doc,
  query,
  collection,
  getDocs,
  deleteDoc,
  getDoc,
  addDoc,
  updateDoc,
  where,
  setDoc
} from "firebase/firestore";

// Function to logged in
const login = async (email, password) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  });
  if (!response.ok) {
    // Extract error message from the response if available
    const errorData = await response.json();
    throw new Error(errorData.message || "Login failed");
  }
  // Now, log the user into Firebase using Firebase Authentication

  return response.json();
};

// Function to get the list of users from Firestore collection
const getBackOfficeUserList = async () => {
  try {
    // Reference to the Firestore collection
    const usersCollection = query(collection(db, "xc_backoffice_users"));
    // Use getDocs to fetch all documents in the collection
    const snapshot = await getDocs(usersCollection);

    if (snapshot.empty) {
      throw new Error("No users found in the backoffice.");
    }

    // Map the documents into an array of user data
    const usersList = snapshot.docs.map((doc) => ({
      id: doc.id, // Document ID
      ...doc.data(), // All the data from the document
    }));

    return usersList;
  } catch (error) {
    console.error("Error fetching backoffice users:", error);
    throw error; // Rethrow the error for the caller to handle
  }
};

// Function to delete a user from Firestore by user ID
const deleteUser = async (userId) => {
  try {
    // Reference to the user document in the Firestore collection
    const userDocRef = doc(db, "xc_backoffice_users", userId);

    // Use deleteDoc to delete the document
    await deleteDoc(userDocRef);
  } catch (error) {
    console.error("Error deleting user:", error);
    throw new Error("Error deleting user: " + error.message);
  }
};

// Function to get a user by ID
const getUserById = async (userId) => {
  try {
    // Reference to the specific user document in Firestore
    const userDocRef = doc(db, "xc_backoffice_users", userId);
    const userDoc = await getDoc(userDocRef);

    if (!userDoc.exists()) {
      throw new Error(`User with ID ${userId} not found.`);
    }

    return {
      id: userDoc.id,
      ...userDoc.data(),
    };
  } catch (error) {
    console.error("Error fetching user by ID:", error);
    throw error;
  }
};

// Function to update a user's information
const updateUser = async (userId, updatedData) => {
  try {
    // Reference to the user document in Firestore
    const userDocRef = doc(db, "xc_backoffice_users", userId);
    // Use updateDoc to modify the user document
    await updateDoc(userDocRef, updatedData);
  } catch (error) {
    console.error("Error updating user:", error);
    throw new Error("Error updating user: " + error.message);
  }
};

// Function to add a new user to the Firestore collection
const addUser = async (userData) => {
  try {
    const user = await getUserByEmail(userData.email);
    if (!user.empty) {
      throw new Error("Error adding user: user exists");
    } else {
      // Reference to the Firestore collection where you want to add the user
      const usersCollectionRef = collection(db, "xc_backoffice_users");
      const docRef = doc(usersCollectionRef);
      const userDataWithId = { ...userData, id: docRef.id };
        await setDoc(docRef, userDataWithId);

      return userDataWithId; // Return the user data with the assigned ID
    }
  } catch (error) {
    console.error("Error adding user:", error);
    throw new Error("Error adding user: " + error.message);
  }
};
const getUser = async (user_id) => {
  let userQuerry = query(
    collection(db, "xc_user"),
    where("firebase_auth_id", "==", user_id)
  );
  const userDoc = await getDocs(userQuerry);
  return userDoc;
};

const getUserByEmail = async (email) => {
  return await getDocs(
    query(collection(db, "xc_backoffice_users"), where("email", "==", email))
  );
};

const getAuthUser = async () => {
  let jwt = JSON.parse(localStorage.getItem("currentuser"))
  return jwtDecode(jwt);
};
export const userService = {
  login,
  getBackOfficeUserList,
  deleteUser,
  getUserById,
  updateUser,
  addUser,
  getUser,
  getAuthUser
};
